<template>
  <div class="pageContol homeWork">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">作业列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员作业列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc"
          style="dispaly: flex; align-items: flex-start"
        >
          <p style="padding: 10px">
            布置作业时间：{{ courseInfo.createTime | momentDate }}
            <span style="margin-left:15px">课程名称：{{ courseInfo.courseName }}</span>
          </p>
          <div class="searchbox">
            <div title="提交时间" class="searchboxItem searchboxItems">
              <span class="itemLabel">提交时间:</span>
              <el-date-picker
                v-model="submitTime"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <div title="提交状态" class="searchboxItem searchboxItems">
              <span class="itemLabel">提交状态:</span>
              <el-select v-model="state" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
                width="100px"
              >
              </el-table-column>
              <el-table-column
                label="手机号码"
                align="center"
                show-overflow-tooltip
                prop="mobile"
                width="100px"
              />
              <el-table-column
                label="状态"
                align="center"
                show-overflow-tooltip
                prop="submitState"
                width="100px"
              >
              </el-table-column>

              <el-table-column
                label="提交时间"
                align="center"
                prop="createTime"
                width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="填写内容"
                align="center"
                show-overflow-tooltip
                prop="taskUserAnswer"
              >
                <template slot-scope="scope">
                  {{ scope.row.taskUserAnswer }}
                </template>
              </el-table-column>
              <el-table-column label="作业" align="center">
                <template slot-scope="scope">
                  <div style="display: flex">
                    <div
                      class="block"
                      style="margin:0 5px"
                      v-for="(item, index) in scope.row.userAppendixAnswerList"
                      :key="index"
                    >
                      <el-image
                        style="width: 50px; height: 50px"
                        :src="item.appendixUrl"
                        :preview-src-list="scope.row.userAppendixAnswerUrlList"
                      ></el-image>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "assignHomework",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseTaskId: "", //作业Id
      state: "", //状态
      submitTime: "", //提交时间
      options: [
        {
          value: "10",
          label: "已提交",
        },
        {
          value: "20",
          label: "未提交",
        },
      ],
      courseInfo: {},
    };
  },
  computed: {},
  methods: {
    init() {
       
      this.getTableHeight();
      this.getInfo();
      this.getData(-1);
    },
    getInfo() {
      this.$post("/biz/course/task/getCourseTaskInfo", {
        courseTaskId: this.$route.query.courseTaskId,
        projectCourseId: this.$route.query.projectCourseId,
      }).then((res) => {
        this.courseInfo = {
          ...res.data,
        };
      });
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseTaskId: this.$route.query.courseTaskId,
        projectId: this.$route.query.projectId,
        projectCourseId: this.$route.query.projectCourseId,
        state: this.state,
      };
      if (this.submitTime) {
        params.startDate = this.submitTime[0];
        params.endDate = this.submitTime[1];
      }
      this.doFetch({
        url: "/biz/course/task/userPageList",
        params,
        pageNum,
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scope>
.el-textarea /deep/.el-textarea__inner {
  min-height: 100px !important;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card i {
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-upload-list--picture-card /deep/.el-upload-list__item-actions {
  z-index: 9999;
}
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>
